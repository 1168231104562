import { useState, useEffect } from "react";
import { useInView } from 'react-intersection-observer';
import MediaThumbnailsChild from "./MediaThumbnailsChild";
import "./App.css";

function MediaThumbnailsParent(props) {

  const { 
    data, 
    activeSection,
    toggleProjectParent, 
    toggleAppMenuApp
  } = props;

  let toggleProject = (ele) => {
    toggleProjectParent(ele)
  };

  const { ref, inView, entry } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    toggleAppMenuApp(inView);
  }, [inView]);


  let renderThumbnails = () => {
    let filteredData = data.filter((ele) => ele.type === activeSection);
    let mapedData = filteredData.map((ele, index) => {
      return (
        <MediaThumbnailsChild
          index={index}
          data={ele}
          activeSection={activeSection}
          toggleProject={toggleProject}
        />
      );
    });
    return (
      <main 
        ref={ref}
        className="media_thumbnail_container">
        {mapedData}
      </main>
    );
  };


  return (
    <div>
      {renderThumbnails()}
    </div>
  );
}

export default MediaThumbnailsParent;
