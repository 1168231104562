import ReactMarkdown from "react-markdown";
import "./App.css";

function PressChild(props) {
  const { data } = props;

  let renderPressChildBlock = () => {
    if (data[0].title !== "") {
      let renderResumeChildBlockCellsMaped = data.map((ele, index) => {
        return (
          <div className="press_cell_block">
            <div>
              <span>
                <a 
                  target="_blank"
                  alt={ele.main_media_or_links}
                  href={ele.main_media_or_links}>
                 {ele.descriptions}
                </a>
              </span>
            </div>
          </div>
        );
      });
      return (
        <div className="resume_blocks_outer">
          <h1>{data[0].subtitles}</h1>
          <div>{renderResumeChildBlockCellsMaped}</div>
        </div>
      );
    }
  };

  return <div>{renderPressChildBlock()}</div>;
}

export default PressChild;
