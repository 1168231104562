import { useState, useEffect, useRef } from "react";
import ReactPlayer from 'react-player';
import "./App.css";

function MediaLightboxChildVideo(props) {

    const {
      src,
    } = props;

    const [videoComponent, setVideoComponent] = useState(null);

    useEffect(() => {
      // Using regex to remove the white spaces
      setVideoComponent(makeVideoComponent(src.replace(/ /g,'')));
    }, [])

    useEffect(() => {
    }, [videoComponent])

    let makeVideoComponent = (src) => {
      return (
        <ReactPlayer
            className='video_slider_media_lightbox'
            controls={true}
            url={src}
        />
      )
    };

    if(videoComponent){
      return (
        <div className="video_slider">
            {videoComponent}
        </div> 
      );
    }else{
      return (
        <div>
          loading video
        </div>
      )
    }
};

export default MediaLightboxChildVideo;
