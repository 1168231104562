import { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import MediaThumbnailsParent from "./MediaThumbnailsParent";
import parse from "html-react-parser";
import ReactMarkdown from "react-markdown";
import ReactPlayer from 'react-player'
import "./App.css";

function MainLightboxParent(props) {

  const {
    data,
    toggleProjectParent
  } = props;

  const [imgSliderDiv, setImgSliderDiv] = useState(null);

  const customSlider = useRef();

  let toggleProject = (data) => {
    toggleProjectParent(data)
  };


  useEffect(() => {

    let slickSlider = () => {
      let settings = {
        dots: false,
        useTransform: false,
        infinite: true,
        speed: 500,
        autoplay: false,
        pauseOnHover: false,
        arrows: true
      };

      let sectionMediaSplittedImg = data
      .filter((ele) => { return ele.slider_img !== ""})
      .map((ele, index) => {

        const {
          slider_img
        } = ele;

        return (
          <div className="main_img_slider_containter">
            <img 
              onClick={() => toggleProject(ele)}
              src={slider_img} />
          </div>
        )
      })
      return (
        <Slider 
          ref={customSlider}
          {...settings}>
            {sectionMediaSplittedImg}
        </Slider>
      )
    };

    setImgSliderDiv(slickSlider());

  }, []);


  let renderSlider = () => {
    if(imgSliderDiv !== null){
      return imgSliderDiv;
    }
  }

  return (
    <section className="slider_container_main_page">
      <div className="slider_container_img">
          {renderSlider()}
      </div>
    </section>
  )
}

export default MainLightboxParent;
