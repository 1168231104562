import { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import _ from "lodash";
import "./App.css";

function ContactParent(props) {
  
  return (
    <div className="contact_container">
      <div className="contact_main_title">
        <h1>CONTACT</h1>
      </div>
      <div className="resume_blocks_outer">

        <div className="press_cell_block">
          <span>
          <a href="mailto:info@chantiermonnet.ca" target="_blank" rel="noreferrer">info@chantiermonnet.ca</a>
          </span>
        </div>
        <div className="press_cell_block">
          <span>
          Instagram: <a href="https://www.instagram.com/coco.monnet/" target="_blank" rel="noreferrer">@coco.monnet</a>
          </span>
        </div>
        <div className="press_cell_block">
          <span>
          Represented by  <a href="https://www.blouin-division.com/en/home" target="_blank" rel="noreferrer">Blouin-Division</a>, Montreal
          </span>
        </div>
      </div>
    </div>
  );
}

export default ContactParent;
