import { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import MediaThumbnailsParent from "./MediaThumbnailsParent";
import ReactPlayer from 'react-player';
import MediaLightboxChild from "./MediaLightboxChild";
import MediaLightboxChildVideo from "./MediaLightboxChildVideo";
import parse from "html-react-parser";
import ReactMarkdown from "react-markdown";
import "./App.css";

function MediaLightboxParent(props) {

  const customSlider = useRef();
  const [imgSliderData, setImgSliderData] = useState(null);
  const [imgSliderDiv, setImgSliderDiv] = useState(null);
  const [isLoader, setIsLoader] = useState(true);
  const [isSliderHovered, setIsSliderHovered] = useState(false)

  const {
      data, 
      arrowFiles, 
      MediaThumbnailsParentData,
      toggleAppMenuAppOnScroll,
      toggleLightboxApp,
      activeSection,
  } = props;

  const {
    descriptions,
    subtitles,
    title,
    section_media
  } = data;


  useEffect(() => {
    let sectionMediaSplitted = section_media.split("|");
    setImgSliderData(sectionMediaSplitted);
    setImgSliderDiv(slickSlider(sectionMediaSplitted));
  }, []);


  useEffect(() => {

    // Might have to add a 
    setImgSliderDiv(null);
    let sectionMediaSplitted = section_media.split("|");
    setImgSliderData(sectionMediaSplitted);
    setImgSliderDiv(slickSlider(sectionMediaSplitted));

    
    setTimeout(() => {
      customSlider.current.slickGoTo(0);
    }, [500])

  }, [title]);


  let returnSliderSettings = () =>  {

    let settings = {
      dots: false,
      useTransform: false,
      infinite: true,
      speed: 500,
      autoplay: false,
      autoplaySpeed: 5000,
      pauseOnHover: false,
      arrows: false,
    };
    return settings;
  }


  let slickSlider = (imgArray) => {
    let sectionMediaSplittedImg = imgArray
    .map((ele, index) => {

      let id = `slide_img_${index}`;
      if(ele.includes("vimeo")){
        return (
          <div className="video_slider">
            <MediaLightboxChildVideo
              src={ele}
            />  
        </div> 
        )
      }else {
        return (
          <div className="img_slider_containter">
            <MediaLightboxChild 
              id={id}
              src={ele}
              next={next}
            />
          </div>
        )
      }
    })

    return (
      <Slider 
        ref={customSlider}
        {...returnSliderSettings()}>
          {sectionMediaSplittedImg}
      </Slider>
    )
  };

  const previous = () => {
    customSlider.current.slickPrev();
  };

  const next = (id) => {
    customSlider.current.slickNext();
  };

  let renderArrowsSliderLeft = () => {
    if(isSliderHovered){
      return (
        <div className="arrows_slider arrow_left" onClick={previous}>
          <img 
            alt={arrowFiles[1]}  
            src={arrowFiles[1]} 
          />
      </div>
      )
    }else return null;
  };

  let renderArrowsSliderRight = () => {
    if(isSliderHovered){
    return (
      <div className="arrows_slider arrow_right" onClick={next}>
          <img 
              alt={arrowFiles[0]}  
              src={arrowFiles[0]} 
          />
      </div>
     )
    }else return null;
  };

  let renderLightboxMedia = () => {

    if(activeSection === "art"){
      return (
        <main 
          onMouseEnter={() => setIsSliderHovered(true)}
          onMouseLeave={() => setIsSliderHovered(false)}
          className="lightbox_main_media">
          {renderArrowsSliderLeft()}
          {imgSliderDiv}
          {renderArrowsSliderRight()}
        </main>
      )
    }else{
      return (
        <div className='player-wrapper'>
        <ReactPlayer
          className='react-player'
          controls={true}
          url={section_media}
          width='100%'
          height='100%'
        />
        </div>
      )

    }
  };

  let dynamicStylingLightboxContent = () => {
    if(activeSection !== "art"){
      return {
        color: "white"
    }
  }
};

    let renderLightboxFooter = () => {
      return (
        <footer 
          style={dynamicStylingLightboxContent()}
          className="footer_lightbox">
          <div className="footer_lightbox_left">
            <div className="lightbox_title">
              <ReactMarkdown>{title}</ReactMarkdown>
            </div>
            <div className="lightbox_subtitles">
              <ReactMarkdown>{subtitles}</ReactMarkdown>
            </div>
          </div>
          <div className="footer_lightbox_right">
            <ReactMarkdown>{descriptions}</ReactMarkdown>
          </div>
        </footer>
      )
    };
   
    let toggleAppMenuApp = (bool) => {
      toggleAppMenuAppOnScroll(bool);
    };


  let renderLightboxContent = () => {
    return (
      <div>
        <div>
        {renderLightboxMedia()}
        </div>
        {renderLightboxFooter()}
      </div>
    )
  }

  if(imgSliderData){

    return (
      <div 
        className="lightbox_container">
          {renderLightboxContent()}
      </div>
    );
  }  
}

export default MediaLightboxParent;
