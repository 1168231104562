import { useState, useEffect } from "react";
import _ from "lodash";
import ReactMarkdown from "react-markdown";
import "./App.css";

import PressChild from "./PressChild";

function PressParent(props) {
  const { data } = props;

  const [pressData, setPressData] = useState(null);

  useEffect(() => {
    const pressObj = _.groupBy(data, (ele) => ele.subtitles);
    let pressArr = _.values(pressObj).reverse();
    setPressData(pressArr);
  }, []);

  let renderPressChild = () => {
    if (pressData) {
      let pressDataMaped = pressData.map((ele, index) => {
        return <PressChild data={ele} />;
      });
      return <div>{pressDataMaped}</div>;   
    };
  };  

  return (
    <div className="press_container">
        <div className="press_main_title">
            <h1>PRESS & PUBLICATION</h1>
        </div>
        {renderPressChild()}
    </div>
  );
}

export default PressParent;
