import { useState, useEffect, useRef } from "react";
import "./App.css";

function MediaLightboxChild(props) {

    const {
      id, 
      src,
      next,
    } = props;

      return (
        <div>
            <img
            id={id}
            onClick={next}
            src={src} />
        </div>
      );
};

export default MediaLightboxChild;
