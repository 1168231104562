import { useState, useEffect } from "react";
import parse from "html-react-parser";
import ReactMarkdown from "react-markdown";
import axios from "axios";
import "./App.css";

function MediaThumbnailsChild(props) {

  const { data, activeSection, toggleProject, index } = props;
  const { main_media_or_links, subtitles, title} = data;

  const [isSubtitleToggled, setIsSubtitleToggled] = useState(false);

  let toggleSubtitles = () => {
    setIsSubtitleToggled(!isSubtitleToggled);
  };

  let renderDynamicStyling = () => {
    if (isSubtitleToggled) {
      return "visible";
    } else {
      return "hidden";
    }
  };

  let renderDynamicStylingText = () => {
    if (activeSection === "art") {
      return "black";
    } else {
      return "white";
    }
  };

  return (
    <section
      onMouseEnter={toggleSubtitles}
      onMouseLeave={toggleSubtitles}
      className="media_thumbnails"
      onClick={() => toggleProject(data)}
    >
      <div className="media_thumbnails_inner">
        <img src={main_media_or_links} />
        <div style={{ visibility: renderDynamicStyling() }}>
          <p style={{ color: renderDynamicStylingText() }}>
            <ReactMarkdown>
            {title}
            </ReactMarkdown>  
          </p>
        </div>
      </div>
    </section>
  );
}

export default MediaThumbnailsChild;
