import React from 'react';
import ReactDOM from 'react-dom/client';
import Favicon from 'react-favicon'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <div>
  <Favicon url='https://res.cloudinary.com/sismographie-cloud/image/upload/v1672063335/stuff/CM_Favicon_Dec2022_Plan_de_travail_1_iyoxo3.png' />
  <React.StrictMode>
    <App />
  </React.StrictMode>
  </div>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
