import ReactMarkdown from "react-markdown";
import "./App.css";

function ResumeChild(props) {
  const { data } = props;

  let renderResumeChildBlock = () => {
    // could also filter it first;
    if (data[0].title !== "") {
      let renderResumeChildBlockCellsMaped = data.map((ele, index) => {
        if (ele.subtitles !== "") {
          return (
            <div className="resume_cells_flex">
              <div>
                <span>{ele.subtitles}</span>
              </div>
              <div>
                <span>{ele.descriptions}</span>
              </div>
            </div>
          );
        } else {
          return (
            <div className="resume_cells_block">
              <div>
                <span>{ele.descriptions}</span>
              </div>
            </div>
          );
        }
      });
      return (
        <div className="resume_blocks_outer">
          <h1>{data[0].title}</h1>
          <div>{renderResumeChildBlockCellsMaped}</div>
        </div>
      );
    }
  };

  return <div>{renderResumeChildBlock()}</div>;
}

export default ResumeChild;
