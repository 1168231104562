import { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import _ from "lodash";
import "./App.css";
import ResumeParent from "./ResumeParent";

function AboutParent(props) {
  const { data } = props;

  const [aboutData, setAboutData] = useState(null);

  useEffect(() => {
    if (data) {
      let aboutObj = _.groupBy(data, (ele) => ele.type);
      setAboutData(aboutObj);
    }
  }, []);

  let renderFirstAboutSection = () => {
    if (!aboutData) {
      return null;
    }

    const { about_image, main_text  } = aboutData;

    return (
      <div>
        <ReactMarkdown className="about_about_outer">
          {main_text[0].descriptions}
        </ReactMarkdown>
        <div className="about_img_outer">
          <img
            src={about_image[0].descriptions}
            alt={about_image[0].descriptions}
          />
          <label>{about_image[0].subtitles}</label>
        </div>
      </div>
    );
  };

  let renderSecondAboutSection = () => {
    if (!aboutData) {
      return null;
    }

    const { resume } = aboutData;

    return <ResumeParent data={resume} />;
  };

  return (
    <div className="about_container">
      {renderFirstAboutSection()}
      {renderSecondAboutSection()}
    </div>
  );
}

export default AboutParent;
