import { useState, useEffect } from "react";
import _ from "lodash";
import "./App.css";

import ResumeChild from "./ResumeChild";

function ResumeParent(props) {
  const { data } = props;

  const [resumeData, setResumeData] = useState(null);

  useEffect(() => {
    if (data) {
      let resumeObj = _.groupBy(data, (ele) => ele.title);
      let resumeArr = _.values(resumeObj);
      setResumeData(resumeArr);
    }
  }, []);

  let renderResumeChild = () => {
    if (resumeData) {
      let resumeDataMaped = resumeData.map((ele, index) => {
        return <ResumeChild data={ele} />;
      });

      return <div>{resumeDataMaped}</div>;
    }
  };

  return <div>{renderResumeChild()}</div>;
}

export default ResumeParent;
