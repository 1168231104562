import { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import parse from 'html-react-parser';
import axios from "axios";

import MediaThumbnailsParent from "./MediaThumbnailsParent";
import MediaLightboxParent from "./MediaLightboxParent";
import AboutParent from "./AboutParent";
import PressParent from "./PressParent";
import ContactParent from "./ContactParent";

import './App.css';
import MainLightboxParent from "./MainLightboxParent";

let fixedContent = {
  header: "caroline monnet",
  firstLevelToggles: ["art", "film"],
  secondLevelToggles: ["about", "press", "contact"],
  animatedHeaderHeight: "300",
  arrowFiles: [
    "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1683134233/caroline_monnet/CM_Arrow__Right_Arrow.svg",
    "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1683134232/caroline_monnet/CM_Arrow__Arrow_Left.svg",
  ]
};

function App() {

  const customSliderMain = useRef();

  const {
    header,
    firstLevelToggles,
    secondLevelToggles,
    animatedHeaderFiles,
    animatedHeaderHeight
  } = fixedContent;

  const [cmsData, setCmsData] = useState(null);
  const [projectsData, setProjectsData] = useState(null);
  const [aboutData, setAboutData] = useState(null);
  const [pressData, setPressData] = useState(null);
  const [disableAnimLogo, setDisableLogoAnim] = useState(false);
  const [activeSection, setActiveSection] = useState(null);
  const [selectedLightboxData, setSelectedLightboxData] = useState(null);
  const [isLoadingScreen, setisLoadingScreen] = useState(null);
  const [isToggleAppMenuApp, setIsToggleAppMenuApp] = useState(true);
  const [selectedProject, setSelectedProject] = useState(null);

  useEffect(() => {
    
    document.body.style.backgroundColor = "#f5f7f2";

    setTimeout(() => {
      setDisableLogoAnim(true)
    }, "2800");

    axios.get("https://sheet.best/api/sheets/e4c66c3e-2f6b-4b69-a79d-fe6b138dcfbe")
    .then(response => setCmsData(response.data))
    .catch(error => { console.log("The axios fetch did not occur");})

  }, []);


  useEffect(() => {

    window.scrollTo(0, 0); 

    let buttonNavCollection = document.getElementsByClassName("buttons_nav");
    let buttonNavArray = [...buttonNavCollection];

    let selectedNavButton = document.getElementById(activeSection);
    let headerLogo = document.getElementById("logo");

    if(buttonNavCollection){
      buttonNavArray.map((ele, index) => {
        return ele.style.color = "gray";
        })
    };

    if(activeSection === "film"){
      document.body.style.backgroundColor = "#202020";
    }else{    
      document.body.style.backgroundColor = "#f5f7f2";
    }

    
  }, [activeSection]);


  useEffect(() => {

    if(cmsData){

      setProjectsData(cmsData.filter(ele => ele.section === "project"));
      setAboutData(cmsData.filter(ele => ele.section === "about"));
      setPressData(cmsData.filter(ele => ele.section === "press"));
    }

    // adding the art as active section
    // when cmsData (Allen's March, 24th)
    setActiveSection("art");

  }, [cmsData]);

  // Test Allen's 
  // useEffect(() => {
  //   setTimeout(() => {
  //     setisLoadingScreen(false)
  //   }, "1000");
  // }, [isLoadingScreen]);

  let toggleSection = (ele) => {
    setSelectedProject(null);
    setActiveSection(ele);
  };

  let reloadWebsite = () => {
    return window.location.reload();
  };

  let renderFixedHeaderFooter = () => {

    let firstLevelTogglesMaped = firstLevelToggles
    .map((ele, index) => {
      return (
        <div>
          <h2 
            className="buttons_nav"
            id={ele}
            onClick={() => { toggleSection(ele)}}
            key={index}>
            {ele}
          </h2>
        </div>  
      )
    });

  
    let toggleFooterSecondary = () => {
      if(isToggleAppMenuApp){
        return "block"
      }else if (activeSection === "press" || activeSection === "about"){
        return "block"
      }else{
        return "none"
      }
    }

    let secondLevelTogglesMaped = secondLevelToggles
    .map((ele, index) => {
        return (
          <div>
            <h2
              className="buttons_nav"
              id={ele}
              onClick={() => { setActiveSection(ele);}}
              key={index}>
                {ele}
            </h2>
          </div>  
        )
    });
    

    let renderDynamicLogoStyling = () => {
      if(activeSection === "film"){
        return "white"
      }else{
        return "black"
      }
    }

    let renderHeader = header.split(" ")
    .map((ele) => {
      return <h2>{ele}</h2>
    });

    let className = `footer_${activeSection}`;


      return (
        <nav className="main_section_nav">
          <header 
            style={{color: renderDynamicLogoStyling()}}
            id="logo"
            onClick={reloadWebsite}>
              <div>
                {renderHeader}
              </div> 
          </header>
          <footer className={className}>
              <div 
                style={{display: toggleFooterSecondary()}}
                className='footer_secondary'>
              {secondLevelTogglesMaped}
              </div>
              <div className='footer_primary'>
                 {firstLevelTogglesMaped}
              </div>
          </footer>   
        </nav>  
      )
  };


    let toggleProject = (data) => {
      setSelectedProject(data);
      setActiveSection(data.type);
      // Test Allens
      // setisLoadingScreen(true);
      window.scrollTo(0, 0);
    }


  let renderThumbnailsParentComponent = () => {
    return (
      <MediaThumbnailsParent
          toggleAppMenuApp={toggleAppMenuApp}
          toggleProjectParent={toggleProject}
          data={projectsData}
          activeSection={activeSection}
      /> 
    )
  };


  let renderAboutSection = () => {
    if(activeSection === "about"){
      return (
        <AboutParent 
          data={aboutData}
        />
      )
    }
  };

  let renderContactSection = () => {
    if(activeSection === "contact"){
      return (
        <ContactParent 
        />
      )
    }
  }

  let renderPressSection = () => {
    if(activeSection === "press"){
      return (
        <PressParent 
          data={pressData}
        />
      )
    }
  }; 

  let toggleAppMenuApp = (bool) => {
    setIsToggleAppMenuApp(true);
  }



  let renderLightboxParentComponent = () => {

    const { arrowFiles } = fixedContent;

    if(selectedProject){
      return (
        <MediaLightboxParent
            toggleAppMenuAppOnScroll={toggleAppMenuApp}
            activeSection={activeSection}
            data={selectedProject}
            arrowFiles={arrowFiles}
          />
      )
    }
  };

  let renderDynamicLogo = () => {
    if(!disableAnimLogo){
      return (
        <main className='main_dynamic_logo'>
            <img src="https://res.cloudinary.com/sismographie-cloud/image/upload/v1667564292/CM_logo_vucfls.gif" />
        </main>
      )
    }
  }

  // the loading "img"
  let renderLoadingScreen = () => {
    if(isLoadingScreen){
      return (
        <div className="loading_screen_projects">
          <img src="https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1683134223/caroline_monnet/CM_logo_Loader.jpg" />
        </div>
      )
    }
  }

  let renderSliderThumbnail = () => {

    if(activeSection === "about" || activeSection === "press"){
      return null;
    }

    return (
      <main>
        {renderLightboxParentComponent()}
        {renderThumbnailsParentComponent()}
      </main>
    )
  }

  let renderMainSite = () => {
    if(disableAnimLogo){
      return (
        <div>
        {renderLoadingScreen()}
        {renderFixedHeaderFooter()}
        {renderSliderThumbnail()}
        {renderAboutSection()}
        {renderContactSection()}
        {renderPressSection()}
      </div>
      )
    }
  }

    return (
      <div className="App">
        {renderDynamicLogo()}
        {renderMainSite()}
      </div>
    )
}



export default App;
